import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { IShopNavigationMenu } from '@components/shared/shop/ShopNavigationMenu';
import SearchBar from '@components/shared/Search/SearchBar/SearchBar';
import { useTheme } from '@components/theme';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import { useRouter } from 'next/router';
import { mapValues } from 'lodash';
import { SHOP_SEARCH_URL } from '@components/pages/ShopV3/util/search';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';
import { INavLink, loggedOutLinks } from '../../components/NavLinks/links';
import NavLinks from '../../components/NavLinks/MobileView/NavLinks';
import styles from './LoggedOutHeader.module.scss';
import Social from '../../components/Social/Social';

interface ILoggedOutHeaderProps {
  className?: string;
  isUnderShopPage: boolean;
  shopNavigationMenu?: IShopNavigationMenu[];
  searchValue: string;
  navLink?: INavLink;
  afterLoginRedirectUrl?: string;
  setSearchValue: (text: string) => void;
  toggleMenu: () => void;
  setNavLink: (navLink?: INavLink) => void;
  cities?: string[];
}
const LoggedOutHeader = ({
  className,
  isUnderShopPage,
  shopNavigationMenu,
  searchValue,
  setSearchValue,
  toggleMenu,
  navLink,
  afterLoginRedirectUrl,
  setNavLink,
}: ILoggedOutHeaderProps) => {
  const theme = useTheme();
  const router: NextRouter = useRouter();
  const { shopNavigationData } = useContext(ShopNavigationContext);

  const [openTabs, setOpenTabs] = useState(
    () => loggedOutLinks(shopNavigationData).reduce(
      (result: Record<string, boolean>, link: any) => {
        return {
          ...result,
          [link.title]: false
        };
      }, {}
    )
  );

  const handleTabClick = (link: INavLink) => {
    if (isUnderShopPage && link.isPaginated) {
      setNavLink(link);
    } else {
      setOpenTabs(mapValues(openTabs, (value, key) => {
        if (link.title === key) return !value;
        return false;
      }));
    }
  };

  if (navLink && navLink.renderSubPage) {
    if (isUnderShopPage) {
      return navLink.renderSubPage({
        shopNavigationMenu,
        handleGoBack: () => setNavLink(undefined)
      });
    }

    return navLink.renderSubPage({
      handleGoBack: () => setNavLink(undefined)
    });
  }

  return (
    <nav className={classNames(
      className,
      styles.LoggedOutHeader
    )}
    >
      <>
        <NavLinks
          navLinks={loggedOutLinks(shopNavigationData)}
          openTabs={openTabs}
          hasSubNav={isUnderShopPage}
          handleTabClick={handleTabClick}
          setNavLink={setNavLink}
        />
        {isUnderShopPage && (
          <SearchBar
            className={classNames(
              theme.styles.Title2,
              theme.styles.Core4,
              styles.SearchWrapper,
              { [styles.ShowCloseIcon]: searchValue.length > 0 }
            )}
            searchIconFirst
            value={searchValue}
            showCloseIcon={searchValue.length > 0}
            onChange={(value) => setSearchValue(value)}
            onClose={() => setSearchValue('')}
            onSubmit={(e) => {
              e.preventDefault();
              const text = searchValue?.trim();

              analyticsTrack('shop_nav_searched', {
                title: `Shop Navigation Menu | Search`,
                searchedText: text,
              });

              router.push({
                pathname: SHOP_SEARCH_URL,
                query: {
                  text
                }
              });
              toggleMenu();
              setSearchValue('');
            }}
          />
        )}

        <div className={styles.Links}>
          <a
            className={theme.styles.Overline}
            href={`/login${
              afterLoginRedirectUrl ? `?redirect=${afterLoginRedirectUrl}` : ''
            }`}
          >
            LOGIN
          </a>
          <a className={theme.styles.Overline} href="/interior-design-style-quiz/style-inspiration">GET STARTED</a>
          <a className={theme.styles.Overline} href="https://havenly.kustomer.help">HELP</a>
        </div>
        <Social />
      </>
    </nav>
  );
};

export default LoggedOutHeader;
