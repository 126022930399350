import Head from 'next/head';
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookie from 'js-cookie';

import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';

import env from '@lib/envs/env';
import classNames from 'classnames';
import { hashCode } from '@lib/string';
import analyticsPage from '@lib/analytics/analyticsPage';
import MainFooter from '@components/shared/footers/MainFooter/MainFooter';
import MainHeader from '@components/shared/Navigation/components/MainHeader/Header';
import UserContext from '@context/User/user-context';
import Navigation, { INavigationProps } from '../../Navigation/Navigation';
import { ITwitterCard } from './i-twitter-card';
import { IFacebookOpenGraph } from './i-facebook-open-graph';
import Messaging from '../../Messaging';
import styles from './DefaultTemplate.module.scss';

export interface IPreloadImages {
  href: string;
  imageSrcSet?: string;
  imageSizes?: string;
  media?: string;
}

interface IDefaultTemplateProps {
  analyticsPageProperties?: {[key: string]: any};
  title?: string;
  description?: string;
  keywords?: string[];
  children?: any;
  twitter?: ITwitterCard;
  facebook?: IFacebookOpenGraph;
  initializeFacebook?: boolean;
  noIndex?: boolean;
  noFollow?: boolean;
  canonicalLink?: string;
  isIosNative?: boolean;
  isFooterVisible?: boolean;
  isNavVisible?: boolean;
  navProps?: INavigationProps;
  scripts?: string[];
  styleSheets?: string[];
  preloadImages?: IPreloadImages[];
  jsonSchema?: any;
  fonts?: string[];
  checkUnbounceBannerHeight?: (unbounceHeight: number) => void;
  fullscreen?: boolean; // Fill/limit the view to the entire visible window area
  className?: string; // Additional styles to apply to the top-level of the page
}

const printTwitterCard = (twitter: ITwitterCard) => Object
  .keys(twitter)
  .map((key: string) => (
    <meta
      key={`twitter:${key}`}
      name={`twitter:${key}`}
      content={(twitter as any)[key]}
    />
  ));

const printFacebookOpenGraph = (facebook: IFacebookOpenGraph) => Object
  .keys(facebook)
  .map((key: string) => {
    if (key === 'structured_image' && (facebook as any).structured_image) {
      return (
        <React.Fragment key={`og:${key}`}>
          <meta property="og:image:url" content={(facebook as any)[key].url} />
          <meta property="og:image:secure_url" content={(facebook as any)[key].secure_url} />
          <meta property="og:image:type" content={(facebook as any)[key].type} />
          <meta property="og:image:alt" content={(facebook as any)[key].alt} />
        </React.Fragment>
      );
    }

    return (
      <meta
        key={`og:${key}`}
        property={`og:${key}`}
        content={(facebook as any)[key]}
      />
    );
  });

const defaultNavProps: INavigationProps = {
  isFixed: false,
  isTransparent: false,
  isOpaque: false,
  isHiddenMobile: false,
  hideMessaging: false,
  showGetStarted: true,
};

const DefaultTemplate: React.FC<IDefaultTemplateProps> = (
  {
    analyticsPageProperties = {},
    title = 'Online Interior Design And Home Inspiration | Havenly',
    description,
    keywords,
    children,
    twitter,
    facebook,
    initializeFacebook = false,
    noIndex = false,
    noFollow = false,
    canonicalLink,
    isIosNative = false,
    isFooterVisible = true,
    isNavVisible = true,
    navProps = {},
    scripts,
    styleSheets,
    preloadImages,
    jsonSchema,
    fonts,
    checkUnbounceBannerHeight = () => {},
    fullscreen = false,
    className = '',
  }: IDefaultTemplateProps,
) => {
  const router = useRouter();
  const { user } = useContext(UserContext);
  const [unbounceBannerHeight, setUnbounceBannerHeight] = useState(0);

  const onUnbounceBannerHeightChange = (height: number) => {
    setUnbounceBannerHeight(height);
    checkUnbounceBannerHeight(height);
  };

  let canonicalUrl: string | undefined;
  if (canonicalLink && !canonicalLink.match(/^https?:\/\/.*/)) {
    canonicalUrl = `https://${env.DOMAIN_NAME}/${canonicalLink.replace(/^\/+/, '')}`;
  } else {
    canonicalUrl = canonicalLink;
  }

  useEffect(() => {
    analyticsPage(undefined, {
      ...analyticsPageProperties,
      url: window.location.href,
      path: window.location.pathname,
      havenly_anonymous_id: Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
      rebrand: true,
    });
  }, [router.asPath]);

  /***************************************************************************
   * BEGIN: Hacky Code Alert!  The Unbounce sticky banners are displayed
   * with 'position: absolute' and adds a margin-top to the BODY tag.  This
   * plays havoc with our 'fullscreen' mode. This effect and state management
   * is intended to capture the height of the sticky banner so that we can
   * alter the height of the main content DIV to subtract out the height of
   * the banner.
   *
   * The banner is loaded well after the DOM is loaded, so we need to add a
   * MutationObserver to catch when the Unbounce elements are added to the
   * page...or removed, when the user dismisses the banner.
   */
  const [extraStyles, setExtraStyles] = useState({});
  useEffect(() => {
    const observer = new MutationObserver(unbounceMutationObserver(
      setExtraStyles,
      onUnbounceBannerHeightChange,
      fullscreen
    ));

    const bodyElement = document.getElementsByTagName('body')!.item(0)!;
    observer.observe(bodyElement, {
      childList: true,
      subtree: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  /**
   * END: Hacky Code Alert!
   ***************************************************************************/

  const globalFonts = [
    'https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Light.woff2',
    'https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular.woff2',
    'https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Medium.woff2',
    'https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Bold.woff2',
    'https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular-Oblique.woff2',
    'https://static.havenly.com/fonts/simula/Simula-Book.woff2',
    'https://static.havenly.com/fonts/simula/Simula-BookItalic.woff2',
    'https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Regular.woff2',
    'https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Medium.woff2',
    'https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Bold.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-Light-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-LightItalic-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-Regular-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-RegularItalic-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-Medium-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-MediumItalic-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-Semibold-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-SemiboldItalic-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-Bold-Web.woff2',
    'https://static.havenly.com/fonts/graphik/Graphik-BoldItalic-Web.woff2'
  ];

  const showNavBar = !isIosNative && isNavVisible;
  return (
    <>
      <div
        data-test="default-template"
        className={classNames(
          'not-snackbar',
          className,
          styles.Page,
          { [styles.Fullscreen]: fullscreen },
        )}
        style={extraStyles}
      >
        <Head>
          <title data-test="default-template__title">
            {title}
          </title>

          {description && (
            <meta
              name="description"
              content={description.substr(0, 160)}
              data-test="default-template__description"
            />
          )}

          {keywords && (
            <meta
              name="keywords"
              content={keywords.join(', ')}
              data-test="default-template__keywords"
            />
          )}

          {
            scripts
            && Array.isArray(scripts)
            && scripts.length > 0
            && scripts.map((s) => <script key={s} src={s} />)
          }

          {
            initializeFacebook
            && <script async defer crossOrigin="anonymous" src={`https://connect.facebook.net/en_US/sdk.js#version=v9.0&appId=${env.FACEBOOK_CLIENT_ID}&xfbml=true&autoLogAppEvents=true`} />
          }

          {
            styleSheets
            && Array.isArray(styleSheets)
            && styleSheets.length > 0
            && styleSheets.map((href) => <link rel="stylesheet" key={href} href={href} />)
          }

          {/* https://web.dev/preload-responsive-images/ */}
          {
            preloadImages
              && Array.isArray(preloadImages)
              && preloadImages.length > 0
              && preloadImages.map(({
                href,
                imageSrcSet,
                imageSizes,
                media,
              }) => {
                return (
                  <link
                    rel="preload"
                    as="image"
                    key={href || imageSrcSet}
                    href={href}
                    data-test="default-template__preload-link"
                    // @ts-ignore
                    imagesrcset={imageSrcSet}
                    imagesizes={imageSizes}
                    media={media}
                    type="image/webp"
                  />
                );
              })
          }

          {
            jsonSchema && (
              // eslint-disable-next-line react/no-danger
              <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }} />
            )
          }

          {
            fonts
            && Array.isArray(fonts)
            && fonts.length > 0
            && fonts.map((href) => (
              <link
                rel="preload"
                as="font"
                crossOrigin="anonymous"
                href={href}
                key={hashCode(href)}
              />
            ))
          }

          {/* preload fonts after any important images */}
          {globalFonts.map((fontHref) => (
            <link
              key={hashCode(fontHref)}
              rel="preload"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
              href={fontHref}
            />
          ))}

          {twitter && printTwitterCard(twitter)}
          {facebook && printFacebookOpenGraph(facebook)}
          {
            canonicalUrl && (
              <link data-test="default-template__canonical" rel="canonical" href={canonicalUrl} />
            )
          }
          {/* verifies with fb we own this domain */}
          <meta name="facebook-domain-verification" content="nyvqfyxec8mlttb47xxx1lx7bj170c" />
        </Head>
        {/*
          don't try to put Robots inside <Head></Head> above - it will break.
          You can't have components inside <Head> for some reason
        */}
        <Robots
          noIndex={noIndex}
          noFollow={noFollow}
        />
        {
          showNavBar && (
            (
              (!user || user.role === 'customer')
            ) ? (
              <MainHeader
                data-test="default-template__navigation"
                isPositionFixed={!!navProps.isFixed}
                isTransparent={!!navProps.isTransparent}
                isConflictingVariant={!!navProps.isConflictingVariant}
                shopNavigationMenu={navProps.shopNavigationMenu}
                showSearch={!!navProps.showSearch}
                hideMessaging={!!navProps.hideMessaging}
                hideNavLinks={!!navProps.hideNavLinks}
                afterLoginRedirectUrl={navProps.afterLoginRedirectUrl}
                unbounceBannerHeight={unbounceBannerHeight}
                {...defaultNavProps}
                {...navProps}
              />
              ) : (
                <Navigation
                  data-test="default-template__navigation"
                  {...defaultNavProps}
                  {...navProps}
                />
              )
          )
        }

        {children}

        {!isIosNative && isFooterVisible && (<MainFooter />)}
      </div>
      <Messaging />
    </>
  );
};

export default DefaultTemplate;

/**
 * Finds the first node in the given NodeList that has the given class name
 * @param nodeList
 * @param className
 */
function findByClassName(nodeList: NodeList, className: string): Node | null {
  for (let idx = 0; idx < nodeList.length; idx += 1) {
    const node = nodeList[idx];
    const { classList } = node as Element;
    if (classList && classList.contains(className)) {
      return node;
    }
  }

  return null;
}

// Assume that unbounce elements larger than this are popups and we shouldn't adjust
// anything in response to these elements being added to the page.
const UNBOUNCE_POPUP_HEIGHT_THRESHOLD = 100;

/**
 * Listens to events on the DOM to detect when the Unbounce sticky banner shows up
 * or is modified in some way.  Adjusts the page styling in response to these events.
 * @param setExtraStyles React setter to update styles.
 */
function unbounceMutationObserver(
  setExtraStyles: React.Dispatch<React.SetStateAction<{}>>,
  onUnbounceBannerHeightChange: (height: number) => void,
  fullscreen = false
) {
  function updateStyleForStickyBanner(stickyBannerElement: Element) {
    if (fullscreen) {
      setExtraStyles({
        height: `calc(100vh - ${stickyBannerElement.clientHeight}px)`,
      });
    }
    onUnbounceBannerHeightChange(stickyBannerElement.clientHeight);
  }

  return (mutationsList: MutationRecord[]) => {
    for (let idx = 0; idx < mutationsList.length; idx += 1) {
      const mutation = mutationsList[idx];

      if (mutation.type === 'childList') {
        // When the banner is closed, remove our style override
        const removedStickyBanner =
          findByClassName(mutation.removedNodes, 'ub-emb-bar');
        if (removedStickyBanner) {
          setExtraStyles({});
          onUnbounceBannerHeightChange(0);
          return;
        }

        // Watch for the addition of the Unbounce elements, specifically, the IFRAME.
        const unbounceContainer = findByClassName(mutation.addedNodes, 'ub-emb-container');
        if (unbounceContainer) {
          const stickyBanner =
            (unbounceContainer as Element).getElementsByClassName('ub-emb-iframe-wrapper');
          if (stickyBanner && stickyBanner.length > 0) {
            const stickyBannerElement = stickyBanner.item(0);
            if (
              stickyBannerElement &&
              stickyBannerElement.clientHeight < UNBOUNCE_POPUP_HEIGHT_THRESHOLD
            ) {
              updateStyleForStickyBanner(stickyBannerElement);
              return;
            }
          }
        }
      } else if (mutation.type === 'attributes') {
        // Mobile banners get their size applied after the element has already been
        // added to the DOM, so watch for changes to the iframe wrapper so we can adjust
        // the size of our DIV appropriately.
        const element = mutation.target as Element;
        if (element.classList.contains('ub-emb-iframe-wrapper')) {
          if (element.clientHeight < UNBOUNCE_POPUP_HEIGHT_THRESHOLD) {
            // It's probably a banner, not a popup
            updateStyleForStickyBanner(element);
          }
        }
      }
    }
  };
}

/**
 * don't try to put this inside a <Head></Head> component.
 * it won't work for some reason.
 * https://github.com/vercel/next.js/issues/8384
 */
function Robots({ noIndex, noFollow }: {noIndex: boolean; noFollow: boolean}) {
  let dataTest = '';
  let content = '';
  if (env.ENV !== 'prod') {
    dataTest = 'default-template__no-index';
    content = 'noindex,nofollow';
  } else if (noIndex) {
    dataTest = 'default-template__no-index';
    content = 'noindex,nofollow';
  } else if (!noIndex && noFollow) {
    dataTest = 'default-template__index-no-follow';
    content = 'index,nofollow';
  } else {
    dataTest = 'default-template__index';
    content = 'index,follow';
  }
  return (
    <Head>
      <meta data-test={dataTest} name="robots" content={content} />
    </Head>
  );
}
