import { useTheme } from '@components/theme';
import classNames from 'classnames';
import React, { MouseEventHandler, useMemo } from 'react';
import styles from './Button.module.scss';

interface IButtonProps {
  className?: string;
  desktopOnly?: boolean;
  label: string;
  url: string;
  type?: 'primary' | 'secondary' | 'transparent' | 'conflicting';
  size?: 'small' | 'regular' | 'large';
  isTransparent?: boolean;
  handleOnClick?: (label: string) => MouseEventHandler<HTMLAnchorElement> | undefined;
}

const Button = ({
  className = undefined,
  desktopOnly = false,
  label,
  url,
  handleOnClick,
  type = 'primary',
  size = 'regular',
  isTransparent = false,
}: IButtonProps) => {
  const theme = useTheme();
  const buttonSize = useMemo(() => {
    switch (size) {
      case 'small':
        return theme.styles.Small;
      case 'large':
        return theme.styles.Large;
      case 'regular':
      default:
        return '';
    }
  }, [size]);

  const buttonType = useMemo(() => {
    switch (type) {
      case 'secondary':
        return theme.styles.Secondary;
      case 'transparent':
        return theme.styles.Transparent;
      case 'conflicting':
        return theme.styles.Conflicting;
      case 'primary':
      default:
        return theme.styles.Primary;
    }
  }, [type]);

  const onClick = (title: string): MouseEventHandler<HTMLAnchorElement> | undefined => {
    if (handleOnClick) {
      return handleOnClick(title);
    }
    return undefined;
  };

  return (
    <a
      rel="nofollow"
      className={classNames(
        className,
        theme.styles.Button,
        buttonSize,
        buttonType,
        { [styles.isDesktopOnly]: desktopOnly },
        { [theme.styles.Transparent]: isTransparent },
      )}
      href={url}
      onClick={onClick(label)}
    >
      {label}
    </a>
  );
};

export default Button;
