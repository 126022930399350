import gql from 'graphql-tag';
import { shopVendorVariantFields } from '@components/shared/shop/graphql/shopVendorVariant';

export default gql`
  ${shopVendorVariantFields}

  query ShopSearch($criteria: ShopSearchInput!) {
    searchShopProducts(criteria: $criteria) {
      total
      hits {
        vendorVariant {
          ...shopProductInfo
          designAward
        }
      }
      page
      pageSize
      facets {
        name
        values {
          id
          value
          count
        }
      }
  }
}`;
