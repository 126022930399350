import React, { ReactElement } from 'react';
import HavenlyLogo from '@components/shared/footers/MainFooter/HavenlyLogo/HavenlyLogo';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import { useMinBreakpoint } from '@hooks/useMediaQuery/useMediaQuery';
import If from '@components/shared/conditional/If/If';
import styles from './NavLinks.module.scss';
import { INavLink } from '../links';

interface INavLinksProps {
  isConflictingStyle: boolean;
  navLinks: INavLink[];
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
}

const NavLinks = ({
  isConflictingStyle,
  navLinks,
  hideNavLinks,
  navLinksOverride,
}: INavLinksProps) => {
  const theme = useTheme();
  const isMediumDesktop = !useMinBreakpoint('medium-desktop');
  return (
    <div className={styles.NavLinks}>
      <a href="/">
        <HavenlyLogo
          className={classNames(
            styles.Logo,
            { [styles.Conflicting]: isConflictingStyle }
          )}
        />
      </a>
      {!!navLinksOverride && (
        <div className={styles.OverrideWrapper}>
          {navLinksOverride()}
        </div>
      )}
      <If condition={!hideNavLinks && !navLinksOverride}>
        <ul className={styles.MenuLinkList}>
          {
            navLinks.filter((f) => !f.mobileOnly).map(({
              title, shortTitle, url, renderSubNav
            }) => {
              return (
                <li key={title} className={styles.MenuLinkContainer}>
                  <a
                    href={url}
                    onClick={handleNavigationClick(title)}
                    className={classNames(
                      styles.MenuLinkTitle,
                      theme.styles.Navigation,
                      { [styles.Conflicting]: isConflictingStyle }
                    )}
                  >
                    <span>{isMediumDesktop && shortTitle ? shortTitle : title}</span>
                  </a>
                  {renderSubNav && (
                    <div className={styles.SubNavContainer}>
                      <nav>
                        <div className={classNames(theme.styles.BorderTop, styles.Outline)} />
                        {renderSubNav()}
                      </nav>
                    </div>
                  )}
                </li>
              );
            })
          }
        </ul>
      </If>
    </div>
  );
};

export default NavLinks;
