const styleQuiz = [
  {
    id: 0,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/minimal.png',
    title: 'Minimal',
    url: '/exp/interior-design-ideas',
  },
  {
    id: 1,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/preppy.png',
    title: 'Preppy',
    url: '/exp/preppy-design-ideas',
  },
  {
    id: 2,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/midcenturymodern.png',
    title: 'Midcentury Modern',
    url: '/exp/midcentury-modern-design-ideas',
  },
  {
    id: 3,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/coastal.png',
    title: 'Coastal',
    url: '/exp/coastal-design-ideas',
  },
  {
    id: 4,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/transitional.png',
    title: 'Transitional',
    url: '/exp/transitional-design-ideas',
  },
  {
    id: 5,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/boho.png',
    title: 'Boho',
    url: '/exp/bohemian-design-ideas',
  },
  {
    id: 6,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/rustic.png',
    title: 'Rustic',
    url: '/exp/rustic-design-ideas',
  },
  {
    id: 7,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/scandinavian.png',
    title: 'Scandinavian',
    url: '/exp/scandinavian-design-ideas',
  },
  {
    id: 8,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/modern.png',
    title: 'Modern',
    url: '/exp/modern-design-ideas',
  },
  {
    id: 9,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/calicool.png',
    title: 'Cali Cool',
    url: '/exp/interior-design-ideas',
  },
  {
    id: 10,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/glam.png',
    title: 'Glam',
    url: '/exp/glam-design-ideas',
  },
  {
    id: 11,
    image:
      'https://s3.amazonaws.com/static.havenly.com/content/React+Homepage/Style+Quiz/classic.png',
    title: 'Classic',
    url: '/exp/classic-design-ideas',
  },
];

export enum links {
  STYLE_QUIZ = '/interior-design-style-quiz',
  STYLE_INSPIRATION = '/interior-design-style-quiz/style-inspiration',
  INTERSTITIAL = '/interior-design-style-quiz/interstitial',
  STYLE_REVEAL = '/rooms/select-package/style-reveal',
  YOUR_NEEDS = '/rooms/select-package/your-needs',
  SELECT_DESIGNER = '/rooms/select-package/designer',
  SELECT_PACKAGE = '/rooms/select-package/package',
  PAYMENT = '/rooms/select-package/payment',
  SELECT_PACKAGE_ENTRYPOINT = '/rooms/select-package',
}

export enum YourNeedsLinks {
  BUDGET = `/rooms/select-package/your-needs/budget`,
  DESIGNER_FIT = `/rooms/select-package/your-needs/designer-fit`,
  HOME_LOCATION = `/rooms/select-package/your-needs/home-location`,
  HOME_OWNERSHIP = `/rooms/select-package/your-needs/home-ownership`,
  HOME_TYPE = `/rooms/select-package/your-needs/home-type`,
  HOME_ZIP_CODE = `/rooms/select-package/your-needs/home-zip-code`,
}

export const BEGIN_STYLE_QUIZ_LINK = links.STYLE_INSPIRATION;
export const SELECT_PACKAGE_ROUTER_LINK = links.SELECT_PACKAGE_ENTRYPOINT;

export default styleQuiz;
