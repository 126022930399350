/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { Fragment } from 'react';
import PurePlusIcon from '@components/shared/images/atoms/PurePlusIcon/PurePlusIcon';
import PureMinusIcon from '@components/shared/images/atoms/PureMinusIcon/PureMinusIcon';

import { useTheme } from '@components/theme';
import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import ChevronRight from './assets/chevron-right.svg';
import styles from './NavLinks.module.scss';
import { ILink, IMegaTab, INavLink } from '../links';
import { IImageMegaMenuLink } from '../../../Desktop/MegaMenu/ImageMegaMenu';
import { IMegaMenuColumn } from '../../../Desktop/MegaMenu/MegaMenu';

interface INavLinksProps {
  navLinks: INavLink[];
  openTabs: Record<string, boolean>;
  hasSubNav: boolean;
  handleTabClick: (navLink: INavLink) => void;
  setNavLink: (navLink?: INavLink) => void;
}

const NavLinks = ({
  navLinks,
  openTabs,
  hasSubNav,
  handleTabClick,
  setNavLink
}: INavLinksProps) => {
  const theme = useTheme();

  return (
    <div>
      {
        navLinks.map((navLink) => {
          const isOpen = openTabs[navLink.title];

          return (
            <Fragment key={navLink.title}>
              <button
                type="button"
                key={navLink.title}
                onClick={() => handleTabClick(navLink)}
                className={classNames(
                  theme.styles.Title2,
                  theme.styles.Core4,
                  { [styles.isOpen]: isOpen },
                  styles.TabHeader
                )}
              >
                {navLink.megaMenu ? (
                  <div>
                    <span className={classNames(theme.styles.Title2, theme.styles.Core4)}>
                      {navLink.title}
                    </span>

                    <span className={styles.Icon}>
                      {navLink.isPaginated && hasSubNav ? (
                        <div className={styles.IsPaginated}>
                          <ChevronRight />
                        </div>
                      ) : (
                        <>
                          {isOpen ? <PureMinusIcon /> : <PurePlusIcon />}
                        </>
                      )}
                    </span>
                  </div>

                ) : (
                  <a
                    href={navLink.url}
                    className={classNames(theme.styles.Title2, theme.styles.Core4)}
                  >
                    {navLink.title}
                  </a>
                )}
              </button>
              {isOpen && (
                <>
                  {
                    navLink.megaMenu?.map((megaTab) => (
                      <div key={megaTab.title}>
                        <NavLinkOrMegaTab
                          megaTab={megaTab}
                          setNavLink={setNavLink}
                        />
                      </div>
                    ))
                  }
                </>
              )}
              <div
                className={classNames(
                  styles.Divider,
                  { [styles.isOpen]: isOpen }
                )}
              />
            </Fragment>
          );
        })
      }
    </div>
  );
};

function NavLinkOrMegaTab({
  megaTab,
  setNavLink
}: {
  megaTab: ILink | IMegaTab | IImageMegaMenuLink | IMegaMenuColumn;
  setNavLink: (navLink?: INavLink) => void;
}) {
  if ('url' in megaTab && megaTab.url) {
    return (
      <NavLink
        key={megaTab.title}
        {...megaTab}
        title={megaTab.title!}
        url={megaTab.url}
      />
    );
  }
  if ('links' in megaTab && megaTab.links) {
    return (
      <MegaTab
        key={megaTab.title}
        {...megaTab}
        title={megaTab.title!}
        links={megaTab.links}
        setNavLink={setNavLink}
      />
    );
  }
  return null;
}

interface INavLinkProps extends ILink {
  setNavLink?: (navLink?: INavLink) => void;
}
function NavLink({
  title,
  url,
  renderSubPage,
  setNavLink
}: INavLinkProps) {
  const theme = useTheme();
  if (renderSubPage && setNavLink) {
    return (
      <button
        type="button"
        onClick={() => {
          handleNavigationClick(title);
          setNavLink({ title, url, renderSubPage });
        }}
        className={classNames(
          theme.styles.Body,
          styles.NavLink
        )}
      >
        {title}
      </button>
    );
  }
  return (
    <a
      href={url}
      onClick={handleNavigationClick(title)}
      className={classNames(
        theme.styles.Body,
        styles.NavLink
      )}
    >
      {title}
    </a>
  );
}

interface IMegaTabProps extends IMegaTab {
  setNavLink?: (navLink?: INavLink) => void;
}
function MegaTab({ title, links, setNavLink } : IMegaTabProps) {
  const theme = useTheme();
  return (
    <>
      {links && (
        <div className={styles.MegaTab}>
          <span
            className={classNames(
              theme.styles.Body,
              styles.MegaTitle
            )}
          >
            {title}
          </span>
          {links.map((link) => (
            <NavLink
              key={link.title}
              title={link.title}
              url={link.url}
              setNavLink={setNavLink}
              renderSubPage={link.renderSubPage}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default NavLinks;

