import React, {
  ReactElement,
  useCallback, useContext, useEffect, useRef, useState
} from 'react';
import { IUser } from '@models/User/i-user';
import { useGetCartItemCount, useGetMostRecentRoomForUser } from '@components/shared/Navigation/Navigation';
import classNames from 'classnames';
import MessagingContext from '@context/User/messaging-context';
import styles from './DesktopHeader.module.scss';
import LoggedOutHeader from './LoggedOutHeader/LoggedOutHeader';
import CustomerHeader from './CustomerHeader/CustomerHeader';

interface IDesktopHeaderProps {
  className?: string;
  user: IUser | null;
  isScrolled?: boolean;
  isTransparent?: boolean;
  isConflictingVariant?: boolean;
  unreadNotificationCount: number;
  hideMessaging?: boolean;
  afterLoginRedirectUrl?: string;
  showGetStarted?: boolean;
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
  cities?: string[];
}

const DesktopHeader = ({
  className = '',
  user,
  isScrolled = false,
  isTransparent = false,
  isConflictingVariant = false,
  unreadNotificationCount,
  hideMessaging = false,
  showGetStarted = true,
  hideNavLinks = false,
  navLinksOverride,
  afterLoginRedirectUrl = '',
  cities
}: IDesktopHeaderProps) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen } = useContext(MessagingContext);

  const userRole = user?.role;

  const messagingRoom = useGetMostRecentRoomForUser(userRole);
  const cartItemCount = useGetCartItemCount(userRole);

  const handleMouseEnter = useCallback(() => {
    return setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    return setIsHovered(false);
  }, []);

  useEffect(() => {
    if (hoverRef?.current) {
    // Add hover event listener
      hoverRef.current.addEventListener('mouseenter', handleMouseEnter);
      hoverRef.current.addEventListener('mouseleave', handleMouseLeave);
    }
    return function cleanup() {
      if (hoverRef?.current) {
        hoverRef.current.removeEventListener('mouseenter', handleMouseEnter);
        hoverRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [hoverRef]);

  const isConflictingStyle = isConflictingVariant && !isScrolled && !isHovered && !isOpen;

  return (
    <div
      ref={hoverRef}
      className={classNames(
        className,
        styles.DesktopHeader,
        { [styles.isScrolled]: isScrolled }
      )}
    >
      {user ? (
        <CustomerHeader
          className={className}
          isConflictingStyle={isConflictingStyle}
          user={user}
          isTransparent={isTransparent}
          isScrolled={isScrolled}
          messagingRoom={messagingRoom}
          cartItemCount={cartItemCount}
          unreadNotificationCount={unreadNotificationCount}
          hideMessaging={hideMessaging}
          hideNavLinks={hideNavLinks}
          cities={cities}
          navLinksOverride={navLinksOverride}
        />

      ) : (
        <LoggedOutHeader
          isConflictingStyle={isConflictingStyle}
          showGetStarted={showGetStarted}
          cities={cities}
          hideNavLinks={hideNavLinks}
          afterLoginRedirectUrl={afterLoginRedirectUrl}
        />
      )}
    </div>
  );
};

export default DesktopHeader;
