export interface ISortOption {
  label: string;
  sort: string;
}

const sortOptions: ISortOption[] = [
  {
    label: 'Recommended',
    sort: 'default'
  },
  {
    label: 'Price - Low to High',
    sort: 'price_asc'
  },
  {
    label: 'Price - High to Low',
    sort: 'price_desc'
  },
];

const defaultSortOption = sortOptions[0];

export function findOption(option: string) {
  return sortOptions.find((o) => o.sort === option);
}

export function isValidOption(option: string) {
  return !!findOption(option);
}

export {
  defaultSortOption,
  sortOptions,
};
