import classNames from 'classnames';
import React, {
  ReactElement,
  useContext, useEffect, useMemo, useState
} from 'react';

import DesktopHeader from '@components/shared/Navigation/components/MainHeader/Desktop/DesktopHeader';
import { IShopNavigationMenu } from '@components/shared/shop/ShopNavigationMenu';
import { useTheme } from '@components/theme';
import MessagingContext from '@context/User/messaging-context';
import UserContext from '@context/User/user-context';
import { userInExperimentVariation } from '@lib/experiment';
import { ExperimentTitles } from '@models/Experiment';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';

import styles from './Header.module.scss';
import MobileHeader from './Mobile/MobileHeader';

interface IHeaderProps {
  isPositionFixed: boolean;
  isTransparent: boolean;
  showGetStarted?: boolean;
  showSearch: boolean;
  hideMessaging: boolean;
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
  afterLoginRedirectUrl?: string;
  isConflictingVariant?: boolean;
  shopNavigationMenu?: IShopNavigationMenu[];
  unbounceBannerHeight?: number;
  cities?: string[];
  // hack, we need to include subnav inside of our nav
  // to fix stacking context problems
  hasSubNav?: boolean;
}

const handleScroll = (setIsScrolled: any) => {
  const scrollPosition = window.scrollY;
  if (scrollPosition > 1) {
    setIsScrolled(true);
  } else {
    setIsScrolled(false);
  }
};

const Header = ({
  isPositionFixed = true,
  isTransparent = true,
  showGetStarted,
  hideMessaging,
  hideNavLinks,
  afterLoginRedirectUrl,
  isConflictingVariant = false,
  shopNavigationMenu = [],
  unbounceBannerHeight = 0,
  cities,
  hasSubNav,
  navLinksOverride
}: IHeaderProps) => {
  const { user, experimentVariations } = useContext(UserContext);
  const { isOpen } = useContext(MessagingContext);

  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const {
    isShopNavigationHovered
  } = useContext(ShopNavigationContext);

  useEffect(() => {
    // Add scroll event listener
    const scrollListenerOptions = {
      capture: true,
      passive: true
    };
    const scrollListener = () => {
      handleScroll(setIsScrolled);
    };
    window.addEventListener('scroll', scrollListener, scrollListenerOptions);

    return function cleanup() {
      window.removeEventListener('scroll', scrollListener, scrollListenerOptions);
    };
  }, []);

  const shouldHideMessaging = useMemo(() => {
    if (hideMessaging) {
      return true;
    } else {
      return userInExperimentVariation(
        ExperimentTitles.CORE_EXPERIENCE_TEST_A,
        experimentVariations
      );
    }
  }, [hideMessaging]);

  return (
    <div
      className={classNames(styles.HeaderContainer, theme.styles.Body, {
        [styles.isPositionFixed]: isPositionFixed || isOpen,
        [styles.isScrolled]: isScrolled,
        [styles.hasSubNav]: !!hasSubNav
      })}
      style={{ top: isPositionFixed ? unbounceBannerHeight : 0 }}
      id="HeaderWrapper"
      data-test={`nav__role-${user?.role || 'logged-out'}`}
    >
      <MobileHeader
        user={user}
        isScrolled={isScrolled}
        shopNavigationMenu={shopNavigationMenu}
        cities={cities}
        afterLoginRedirectUrl={afterLoginRedirectUrl}
        isConflictingVariant={isConflictingVariant}
        isTransparent={isTransparent}
      />
      <DesktopHeader
        user={user}
        isScrolled={isScrolled || isShopNavigationHovered}
        isConflictingVariant={isConflictingVariant}
        isTransparent={isTransparent}
        unreadNotificationCount={0}
        hideMessaging={shouldHideMessaging}
        showGetStarted={showGetStarted}
        hideNavLinks={hideNavLinks}
        afterLoginRedirectUrl={afterLoginRedirectUrl}
        cities={cities}
        navLinksOverride={navLinksOverride}
      />
    </div>
  );
};

export default Header;
